import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { AdvancedPerformanceChartMode, AdvancedPerformanceTableMode, APIAssetSearchItem, CorporateActionDashboardItem, CorporateActionsDashboard, getCorpActionTypeStr, IndexCardItem, IndexMaster, PriceValuesByAMID, SortConfig, TableType, UserProfile, UserProfileKeys, Watchlist, WatchlistCardItem, WatchlistEditItem, WatchlistItem, WatchlistSearch, WatchlistTypeEnum, WatchlistWithLoading } from "../../constants";
import { createDateObjectIgnoreTimeZone, getWatchlistPriceURLs } from "../../utilities";
import dateFormat from "dateformat";
import { SetActivePortfolioPayload } from "./portfoliosReducer";
import { Big } from 'big.js';
import { BarChartData } from "../../constants/barCharts";
import { formatPercent } from "../../utilities/numberFormat";
import { Actions as LoginActions } from './loginReducer';
import { Actions as PerformanceActions } from './performanceReducer';

export interface SharedDashboardState {
    CorporateActions: CorporateActionsDashboard;
    IndicesWatchlist: WatchlistWithLoading;
    PerformanceIndicesWatchlist: WatchlistWithLoading;
    PerformanceSingleIndex?: WatchlistItem;
    XIRRBenchmarkSingleIndex?: WatchlistItem;
    WatchList: WatchlistWithLoading;
    WatchListSearch: WatchlistSearch;
    IndicesMaster: IndexMaster[];
    IndicesForPerformanceMaster?: IndexMaster[];
    UserProfile: UserProfile;
    IsUserProfileFetchedFromAPI: boolean;
    TableConfigs: {
        [key in TableType]?: {
            sortConfig: SortConfig;
            tableMode?: AdvancedPerformanceTableMode;
        };
    };
}

const initialState: SharedDashboardState = {
    CorporateActions: {
        IsLoading: true
    },
    IndicesWatchlist: {
        IsLoading: true
    },
    PerformanceIndicesWatchlist: {
        IsLoading: true
    },
    WatchList: {
        IsLoading: true
    },
    WatchListSearch: {
        IsLoading: true
    },
    IndicesMaster: [],
    IndicesForPerformanceMaster: [],
    PerformanceSingleIndex: {
        AssetName: 'TRI Nifty 50',
        MProfitCode: 99906
    },
    XIRRBenchmarkSingleIndex: undefined,
    UserProfile: {
        ShowZeroValues: true,
        ShowInLakhsMillions: true,
        ShowDecimals: false,
        Separator: 'IN'
    },
    IsUserProfileFetchedFromAPI: false,
    TableConfigs: {},
}

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        refreshIndicesMaster: (state) => {

        },
        setIndicesMaster: (state, action: PayloadAction<IndexMaster[]>) => {
            state.IndicesMaster = action.payload;
        },
        refreshIndicesForPerformanceMaster: (state) => {

        },
        setIndicesForPerformanceMaster: (state, action: PayloadAction<IndexMaster[]>) => {
            state.IndicesForPerformanceMaster = action.payload;
        },
        setCorporateActions: (state, action: PayloadAction<CorporateActionDashboardItem[]>) => {
            state.CorporateActions = {
                IsLoading: false,
                List: action.payload
            };
        },
        refreshCorporateActions: (state, action: PayloadAction<SetActivePortfolioPayload>) => {
            state.CorporateActions = {
                IsLoading: true
            };
        },
        updateIndicesWatchlist: (state, action: PayloadAction<{ WatchlistType: WatchlistTypeEnum, Indices: { [MProfitCode: number]: boolean } }>) => {

        },
        setIndicesWatchlist: (state, action: PayloadAction<Watchlist>) => {
            state.IndicesWatchlist = {
                IsLoading: false,
                Watchlist: action.payload
            };

            if (action.payload && action.payload.Items && action.payload.Items.length > 0) {
                state.IndicesWatchlist.WatchlistPriceURLs = getWatchlistPriceURLs(action.payload.Items);
            }
        },
        refreshIndices: (state) => {
            state.IndicesWatchlist = {
                IsLoading: true
            };
        },
        setIndicesPriceValues: (state, action: PayloadAction<PriceValuesByAMID>) => {
            state.IndicesWatchlist.WatchlistPriceValues = action.payload;
        },
        refreshIndicesPrices: () => { },
        setPerformanceIndicesWatchlist: (state, action: PayloadAction<Watchlist>) => {
            state.PerformanceIndicesWatchlist = {
                IsLoading: false,
                Watchlist: action.payload
            };
        },
        refreshPerformanceIndices: (state, action: PayloadAction<{ IsUserUpdate?: boolean }>) => {
            state.PerformanceIndicesWatchlist = {
                IsLoading: true
            };
        },
        setPerformanceSingleIndex: (state, action: PayloadAction<{ IndexItem: WatchlistItem, AdvancedPerformanceChartMode?: AdvancedPerformanceChartMode }>) => {
            state.PerformanceSingleIndex = action.payload.IndexItem;
        },
        setXIRRBenchmarkSingleIndex: (state, action: PayloadAction<{ IndexItem: WatchlistItem, NoRefresh?: boolean }>) => {
            state.XIRRBenchmarkSingleIndex = action.payload.IndexItem;
        },
        refreshWatchList: (state) => {
            state.WatchList.IsLoading = true;
        },
        setWatchList: (state, action: PayloadAction<Watchlist>) => {
            state.WatchList.IsLoading = false;
            state.WatchList.Watchlist = action.payload;

            if (action.payload && action.payload.Items && action.payload.Items.length > 0) {
                state.WatchList.WatchlistPriceURLs = getWatchlistPriceURLs(action.payload.Items);
            }
        },
        setWatchlistPriceValues: (state, action: PayloadAction<PriceValuesByAMID>) => {
            state.WatchList.WatchlistPriceValues = action.payload;
        },
        refreshSearchWatchlistListAPI: (state, action: PayloadAction<string>) => {
            state.WatchListSearch = {
                IsLoading: true,
                Term: action.payload
            }
        },
        setSearchWatchList: (state, action: PayloadAction<APIAssetSearchItem[]>) => {
            state.WatchListSearch.IsLoading = false;
            state.WatchListSearch.Items = action.payload;
        },
        addItemToWatchList: (state, action: PayloadAction<WatchlistEditItem>) => {
        },
        removeItemFromWatchList: (state, action: PayloadAction<WatchlistEditItem>) => {
        },
        refreshWatchlistPrices: (state) => {
        },
        updateWatchList: (state, action: PayloadAction<WatchlistEditItem[]>) => {

        },
        onCloseSearchWatchList: (state) => {
            state.WatchListSearch = initialState.WatchListSearch;
        },
        clearWatchListWithPrices: (state) => {
            state.WatchList = initialState.WatchList;
        },
        fetchUserProfile: (state) => {

        },
        setUserProfileFromAPI: (state, action: PayloadAction<UserProfileItemPayload[]>) => {
            state.IsUserProfileFetchedFromAPI = true;
            if (action.payload?.length > 0) {
                action.payload.forEach((item) => {
                    (state.UserProfile[item.Key] as any) = item.Value;
                })
            }
        },
        addUpdateUserProfileItem: (state, action: PayloadAction<{ Item: UserProfileItemPayload, NoUpdateOnServer?: boolean }>) => {
            if (!action.payload.Item) return;

            (state.UserProfile[action.payload.Item.Key] as any) = action.payload.Item.Value;
        },
        setTableConfig: (state, action: PayloadAction<{ tableType: TableType, sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode }>) => {
            state.TableConfigs[action.payload.tableType] = {
                sortConfig: action.payload.sortConfig,
                tableMode: action.payload.tableMode
            };
        },
        initializeTableConfig: (state, action: PayloadAction<{ tableType: TableType, sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode }>) => {
            if (!state.TableConfigs[action.payload.tableType]) {
                state.TableConfigs[action.payload.tableType] = {
                    sortConfig: action.payload.sortConfig,
                    tableMode: action.payload.tableMode
                };
            }
        },
        setTableConfigMode: (state, action: PayloadAction<{ tableType: TableType, tableMode: AdvancedPerformanceTableMode }>) => {
            if (state.TableConfigs[action.payload.tableType]) {
                state.TableConfigs[action.payload.tableType]!.tableMode = action.payload.tableMode;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginActions.logout, () => initialState)
            .addCase(PerformanceActions.clearCachedXIRR, (state) => {
                state.XIRRBenchmarkSingleIndex = undefined;
            })
            .addCase(LoginActions.resetData, () => initialState)
    }
});


//#region Actions

//#endregion

//#region Payloads

export interface WatchlistPayload { WatchlistType: WatchlistTypeEnum; WatchlistID?: string, CCID: number }
export interface UserProfileItemPayload { Key: UserProfileKeys; Value: any }

//#endregion

export default DashboardSlice.reducer;
export const Actions = { ...DashboardSlice.actions };

//#region Selectors

const dashboardSelector = (state: ClientRootState) => state.shared.dashboard;
const selectCorporateActionsRaw = (state: ClientRootState) => dashboardSelector(state).CorporateActions;
const selectCorporateActionsIsLoading = (state: ClientRootState) => selectCorporateActionsRaw(state).IsLoading;
const selectWatchListSearch = (state: ClientRootState) => dashboardSelector(state).WatchListSearch;
const selectSearchWatchListIsLoading = (state: ClientRootState) => selectWatchListSearch(state).IsLoading;
const selectSearchWatchListItems = (state: ClientRootState) => selectWatchListSearch(state).Items;
// const selectWatchListWithPricesRaw = (state: ClientRootState) => dashboardSelector(state).WatchListWithPrice;

const selectWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).WatchList;
const selectWatchlistIsLoading = (state: ClientRootState) => selectWatchlistRaw(state).IsLoading;
const selectWatchlist = (state: ClientRootState) => selectWatchlistRaw(state).Watchlist;
const selectWatchlistPriceURLs = (state: ClientRootState) => selectWatchlistRaw(state).WatchlistPriceURLs;
const selectWatchlistPriceValues = (state: ClientRootState) => selectWatchlistRaw(state).WatchlistPriceValues;

// const selectWatchListWithPricesIsLoading = (state: ClientRootState) => selectWatchListWithPricesRaw(state).IsLoading;

const selectWatchListWithPrices = createSelector([selectWatchlist, selectWatchlistPriceValues],
    (watchlist, priceValues): WatchlistCardItem[] | undefined => {
        if (!watchlist || !watchlist.Items) return undefined;

        var watchlistValues: WatchlistCardItem[] = [];

        watchlist.Items.forEach((item, index) => {

            var prices = priceValues ? priceValues[item.MProfitCode] : undefined;

            if (prices) {
                watchlistValues.push({
                    MProfitCode: item.MProfitCode,
                    AssetName: item.AssetName,
                    Price: Big(prices.CP),
                    TGain: Big(prices.CP).minus(prices.PP),
                    TGainPct: Big(prices.PP).gt(0) ? Big(prices.CP).div(prices.PP).minus(1) : undefined,
                    TUp: Big(prices.CP).gt(prices.PP)
                })
            } else {
                watchlistValues.push({
                    MProfitCode: item.MProfitCode,
                    AssetName: item.AssetName
                })
            }
        })

        return watchlistValues;
    })

const selectCorporateActionsList = createSelector([selectCorporateActionsRaw, (state, AMID: number) => AMID], (x, AMID) => {
    if (x == null || x.IsLoading || x.List == null) {
        return [];
    } else {
        return (AMID > 0 ? x.List.filter(x => x.AMID === AMID) : x.List).map<CorporateActionDashboardItem>(c => ({
            ...c,
            CorpActionTypeStr: getCorpActionTypeStr(c.CorpActionType),
            CorpActionDateDisplay: dateFormat(createDateObjectIgnoreTimeZone(c.CorpActionDateStr), 'd mmm, yyyy')
        }));
    }
});

const selectIndicesWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).IndicesWatchlist;
const selectIndicesWatchlistIsLoading = (state: ClientRootState) => selectIndicesWatchlistRaw(state).IsLoading;
const selectIndicesWatchlist = (state: ClientRootState) => selectIndicesWatchlistRaw(state).Watchlist;
const selectIndicesWatchlistPriceURLs = (state: ClientRootState) => selectIndicesWatchlistRaw(state).WatchlistPriceURLs;
const selectIndicesWatchlistPriceValues = (state: ClientRootState) => selectIndicesWatchlistRaw(state).WatchlistPriceValues;

const selectTodayPerformanceIndicesChartValues = createSelector([selectIndicesWatchlist, selectIndicesWatchlistPriceValues], (watchlist, priceValues) => {
    if (!priceValues || !watchlist || !watchlist.Items) return undefined;

    var chartValues: BarChartData[] = [];

    watchlist.Items.forEach((item, index) => {
        var prices = priceValues[item.MProfitCode];
        if (prices) {
            var valueBig = Big(prices.CP).div(prices.PP).minus(1);
            chartValues.push({
                Name: item.AssetName || `Index ${index}`,
                Value: valueBig.toNumber(),
                ValueStr: formatPercent(valueBig, 2, 'IN')
            })
        }
    })

    return chartValues;
});

const selectLeftNavIndexCardData = createSelector([selectIndicesWatchlist, selectIndicesWatchlistPriceValues],
    (watchlist, priceValues) => {
        if (!priceValues || !watchlist || !watchlist.Items) return undefined;

        var indexValues: IndexCardItem[] = [];

        watchlist.Items.forEach((item, index) => {

            var prices = priceValues[item.MProfitCode];

            if (prices) {
                indexValues.push({
                    IndexName: item.AssetName || `Index ${index}`,
                    IndexValue: Big(prices.CP),
                    TGain: Big(prices.CP).minus(prices.PP),
                    TGainPct: Big(prices.PP).gt(0) ? Big(prices.CP).div(prices.PP).minus(1) : Big(0),
                    TUp: Big(prices.CP).gt(prices.PP)
                })
            }
        })

        return indexValues;
    })

const selectIndicesMaster = (state: ClientRootState) => dashboardSelector(state).IndicesMaster;
const selectIndicesForPerformanceMaster = (state: ClientRootState) => dashboardSelector(state).IndicesForPerformanceMaster || [];

const selectPerformanceIndicesWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).PerformanceIndicesWatchlist;
const selectPerformanceIndicesWatchlistIsLoading = (state: ClientRootState) => selectPerformanceIndicesWatchlistRaw(state).IsLoading;
const selectPerformanceIndicesWatchlist = (state: ClientRootState) => selectPerformanceIndicesWatchlistRaw(state).Watchlist;

const selectPerformanceSingleIndex = (state: ClientRootState) => dashboardSelector(state).PerformanceSingleIndex;
const selectXIRRBenchmarkSingleIndex = (state: ClientRootState) => dashboardSelector(state).XIRRBenchmarkSingleIndex;

const selectUserProfile = (state: ClientRootState) => dashboardSelector(state).UserProfile;
const selectIsUserProfileFetchedFromAPI = (state: ClientRootState) => dashboardSelector(state).IsUserProfileFetchedFromAPI;
const selectShowInLakhsMillions = (state: ClientRootState) => selectUserProfile(state).ShowInLakhsMillions;
const selectShowDecimals = (state: ClientRootState) => selectUserProfile(state).ShowDecimals;
const selectShowTutorial_Main = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Main !== true;
const selectShowTutorial_Today = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Today !== true;
const selectShowTutorial_Holding = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Holding !== true;
const selectShowTutorial_Performance = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Performance !== true;
const selectTableConfig: (tableType: TableType) => (state: ClientRootState) => { sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode } | undefined = (tableType: TableType) => (state: ClientRootState) => state.shared.dashboard.TableConfigs[tableType];

export const Selectors = {
    selectIndicesMaster,
    selectIndicesForPerformanceMaster,
    selectCorporateActionsList,
    selectCorporateActionsIsLoading,
    selectIndicesWatchlistIsLoading,
    selectIndicesWatchlist,
    selectIndicesWatchlistPriceURLs,
    selectTodayPerformanceIndicesChartValues,
    selectLeftNavIndexCardData,
    selectWatchListSearch,
    selectSearchWatchListItems,
    selectSearchWatchListIsLoading,
    selectWatchlistIsLoading,
    selectWatchlistPriceURLs,
    selectWatchlistRaw,
    selectWatchlistPriceValues,
    selectWatchListWithPrices,
    selectPerformanceIndicesWatchlist,
    selectPerformanceIndicesWatchlistIsLoading,
    selectPerformanceSingleIndex,
    selectXIRRBenchmarkSingleIndex,
    selectUserProfile,
    selectShowInLakhsMillions,
    selectShowDecimals,
    selectShowTutorial_Main,
    selectShowTutorial_Today,
    selectShowTutorial_Holding,
    selectShowTutorial_Performance,
    selectTableConfig
};

//#endregion
