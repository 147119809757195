import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MultiSelectModal } from "../multi-select-modal";
import React from "react";

interface PerformanceIndicesModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    maxCount?: number;
}

export const PerformanceIndicesModal = React.memo(({isModalOpen, setIsModalOpen, maxCount = 5}: PerformanceIndicesModalProps ) => {
    const performanceIndicesList = useAppSelector(SharedRedux.Dashboard.Selectors.selectPerformanceIndicesWatchlist);

    const dispatch = useAppDispatch();

    const onSave = (selectedIndices: {[MProfitCode: number]: boolean}) => {
      dispatch(SharedRedux.Dashboard.Actions.updateIndicesWatchlist({WatchlistType: SharedConstants.WatchlistTypeEnum.PerformanceIndices, Indices: selectedIndices}));
      setIsModalOpen(false);
    }

    const title = 'Select Indices';
    const subtitle = 'Select the indices that you wish to compare your portfolio against';

    const indicesMaster = useAppSelector(SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMaster);

    return (
      <MultiSelectModal
        {...{ isModalOpen, setIsModalOpen, maxCount }}
        onSave={onSave}
        initialSelectedItems={performanceIndicesList?.Items}
        list={indicesMaster}
        idField={'MProfitCode'}
        textField={'IndexName'}
        title={title}
        subtitle={subtitle}
      />
    );
});